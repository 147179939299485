import { Button, Checkbox, Form, Input, notification } from "antd"
import Image from "next/image"
import Link from "next/link"
import React, { useState } from "react"

import { signUp } from "supertokens-auth-react/recipe/emailpassword"

import css from "@css/modules/Layout.module.scss"
import settingsStyles from "@css/modules/Settings.module.scss"

import { Apple, Google } from "@css/icons/SVG"
import logo from "@public/images/logo34x24@2x.png"
import { emailRules, nameRules, passRules, passRulesDefinition } from "./validation"
import { useGeneralData } from "@components/GeneralData/GeneralData"
import { CKEditor } from "@components/CKEditor"
import { updateUserProps } from "@lib/api/user"
import { mutate } from "swr"
import { appInfo } from "config/appInfo"

export function SignUpPanel({ isMobile, thirdPartySignInUp, toggleSignInUp, onClose }) {
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const onFinish = async (values) => {
        let response
        try {
            setIsLoading(true)
            response = await signUp({
                formFields: [
                    {
                        id: "first_name",
                        value: values.first_name
                    },
                    {
                        id: "last_name",
                        value: values.last_name
                    },
                    {
                        id: "email",
                        value: values.email
                    },
                    {
                        id: "password",
                        value: values.password
                    }
                ]
            })

            if (response.status === "FIELD_ERROR") {
                const invalidFields = response.formFields.map((i) => {
                    return {
                        name: i.id,
                        value: values[i.id],
                        errors: [i.error]
                    }
                })
                form.setFields(invalidFields)
            } else {
                notification.open({
                    message: "Thanks for joining us! You're now logged in.",
                    type: "success"
                })
                mutate(`${appInfo.apiDomain}/api/user`)
                form.resetFields()
                toggleSignInUp()
                onClose()
                await updateUserProps({ email_notification: values.email_notification !== false })
            }
        } catch (err) {
            notification.open({
                type: "error",
                message: err.message ? err.message : "Sign-up Error. Try again."
            })
        } finally {
            setIsLoading(false)
        }
    }
    const { tiers } = useGeneralData()
    const freeTier = tiers.find((tier) => tier.level === 0)

    return (
        <>
            {!isMobile && (
                <div className={css.leftSide}>
                    <Image src={logo} alt="DVC For Less logo" className={css.loginModalLogo} />
                    <div className={css.signupLeftSideContent}>
                        <h3 className={css.signupTitle}>The easiest way to shop the entire DVC market</h3>
                        {freeTier && (
                            <>
                                <h3 className={css.tierTitle}>{freeTier.name}</h3>
                                <CKEditor content={freeTier.levelInfo} className={css.tierInfo} />
                            </>
                        )}
                    </div>
                </div>
            )}
            <div className={css.rightSide}>
                <div className={css.signInPanelRoot}>
                    {isMobile && <Image src={logo} alt="DVC For Less logo" className={css.loginModalLogo} />}
                    <h3 className={css.rightSideTitle}>Sign Up</h3>
                    <p className={css.rightSubSideTitle}>Sign up and continue looking for the ideal contract</p>

                    <Form form={form} className={settingsStyles.personalForm} layout="vertical" onFinish={onFinish}>
                        <Form.Item label="First Name" name="first_name" rules={nameRules} requiredMark={"optional"}>
                            <Input autoComplete="given-name" />
                        </Form.Item>
                        <Form.Item label="Last Name" name="last_name" rules={nameRules} requiredMark={"optional"}>
                            <Input autoComplete="family-name" />
                        </Form.Item>
                        <Form.Item label="Email" name="email" rules={emailRules} requiredMark={"optional"}>
                            <Input autoComplete="email" />
                        </Form.Item>

                        <Form.Item label="Password" name="password" className={css.passField} rules={passRules} requiredMark={"optional"}>
                            <Input.Password autoComplete="new-password" passwordrules={passRulesDefinition} />
                        </Form.Item>
                        <Form.Item valuePropName="checked" className={css.subscribeField} label="Join our newsletter for exclusive offers and updates!" name="email_notification">
                            <Checkbox name="email_notification" defaultChecked />
                        </Form.Item>
                        <Form.Item className="last">
                            <Button loading={isLoading} htmlType="submit" className={css.signInPanelBtn}>
                                Sign Up
                            </Button>
                        </Form.Item>
                    </Form>
                    <div className={css.signInPanelDivider}>or</div>
                    <div className={css.signInPanelOverIcon}>
                        {/*                <button aria-label="Facebook login" className={styles.signInPanelIconBtn} onClick={handler({ provider: "Facebook" })}
                        children={<Facebook />} />*/}
                        <button aria-label="Google login" className={css.signInPanelIconBtn} onClick={() => thirdPartySignInUp({ provider: "google" })} children={<Google />} />
                        <button
                            aria-label="Apple login"
                            className={`${css.signInPanelIconBtn} ${css.appleButton}`}
                            onClick={() => thirdPartySignInUp({ provider: "apple" })}
                            children={<Apple />}
                        />
                    </div>
                    <div className={css.signUpLine}>
                        Already have an account ? <button onClick={toggleSignInUp}>Sign In</button>
                    </div>
                    <div className={css.forgotPassword}>
                        By continuing, you agree to our{" "}
                        <Link href="/terms" onClick={onClose}>
                            Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link href="/privacy" onClick={onClose}>
                            Privacy Policy
                        </Link>
                        .
                    </div>
                </div>
            </div>
        </>
    )
}
